import { SIcon } from '@strana-artw/ui-kit';
export default {
  name: 'InputConfirm',
  components: {
    SIcon: SIcon
  },
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    // FixMe: сделать required
    validation: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  methods: {
    confirm: function confirm() {
      this.validation.$touch();
      if (!this.validation.$anyError) {
        this.$emit('confirm');
      }
    }
  }
};